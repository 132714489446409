<template>
  <v-container fluid>
    <v-row>
      <v-col class="col-12">
        <h1>
          My Open Quotations
          <span class="float-right">
            Total value: 
            {{ total | priceInPounds | currency }}
          </span>
        </h1>
        <v-simple-table
          fixed-header
          height="450px"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">No</th>
                <th class="text-left">Customer</th>
                <th class="text-left">Date</th>
                <th class="text-left">Site</th>
                <th class="text-left">Expires</th>
                <th class="text-left">Value</th>
                <th class="text-left">By</th>
                <th class="text-left">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="quotation in quotations"
                :key="quotation.id"
              >
                <td>{{ quotation.jobNumber }} {{ quotation.expired }}</td>
                <router-link :to="`/customers/view/${quotation.customerId}`">
                  {{ quotation.customer }}
                </router-link>
                <td>{{ quotation.date | tinyDate }}</td>
                <td>{{ quotation.site }}</td>
                <td>{{ quotation.expires | tinyDate }}</td>
                <td>
                  <span v-if="quotation.value > quotation.minChargeAmount">
                    {{ quotation.value | priceInPounds | currency }}
                  </span>
                  <span v-else>
                    {{ quotation.minChargeAmount | priceInPounds | currency }}
                  </span>
                </td>
                <td>{{ quotation.quotedByInitials }}</td>
                <td>
                  <router-link custom v-slot="{ navigate }" :to="`/quotations/view/${quotation.id}`">
                    <v-btn
                      x-small
                      @click="navigate"
                      @keypress.enter="navigate"
                      role="link">
                      View
                    </v-btn>
                  </router-link>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-pagination
          class="pt-4"
          v-model="page"
          :length="length"
          prev-icon="mdi-menu-left"
          next-icon="mdi-menu-right"
          :total-visible="totalVisible"
        ></v-pagination>
      </v-col>
    </v-row>
    <router-view></router-view>
  </v-container>
</template>

<script>
import axios from '../../axios';

export default {
  name: 'QuotationsIndex',
  computed: {
    token() {
      return this.$store.state.token;
    },
    userid() {
        return this.$store.state.userid;
    },
  },
  data() {
    return {
      quotations: [],
      page: 1,
      length: 0,
      totalVisible: 9,
      total: 0,
    };
  },
  watch: {
    page() {
      this.getQuotations();
    },
  },
  methods: {
    getQuotations() {
      axios.get(`/quotations/getMyOpen/${this.page}/${this.userid}.json?token=${this.token}`)
        .then((response) => {
          this.quotations = response.data.quotations;
          this.length = response.data.length;
          this.total = response.data.total;
        })
        .catch((error) => {
          console.log(error);
          this.hide = false;
        });
    },
  },
  mounted() {
    this.getQuotations();
  },
};
</script>
